import React, { useState, useEffect } from 'react';

import Cancel from '../../images/icons/i-cancel.svg';

const BottomSheet = ({
  title,
  children,
  isShow,
  setIsShow,
  persistant,
  noTitle,
  noRounded,
  orderCancel = () => {},
}) => {
  const [show, setShow] = useState(false);
  const sheetShow = () => setShow(true);
  const sheetHide = () => {
    setShow(false);
    orderCancel();
    if (setIsShow !== undefined && typeof setIsShow === 'function') {
      setIsShow(false);
    }
  };
  useEffect(() => {
    if (isShow) {
      sheetShow();
    } else {
      sheetHide();
    }
  }, [isShow]);
  return (
    <>
      <div className={'bottom-sheet' + (show || persistant ? ' show' : ' off')}>
        {noTitle ? (
          <></>
        ) : (
          <div className="header-bar">
            {persistant ? (
              <></>
            ) : (
              <img
                src={Cancel}
                alt="cancel"
                className="header-cancel"
                onClick={sheetHide}
              />
            )}

            <div className="header-title">{title}</div>
          </div>
        )}

        <main className="sheet-content">{children}</main>
      </div>
      <style jsx>
        {`
          .bottom-sheet {
            max-height: calc(100% - 16px);
            padding: 20px 16px;
            box-sizing: border-box;
            width: 100%;
            position: fixed;
            z-index: 10;
            bottom: -100vh;
            background-color: #fff;
            border-radius: ${noRounded ? '0' : '16px 16px 0px 0px'};
            filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.161));
            transition: all 0.5s;
            &.show {
              bottom: 0;
              z-index: 200;
              transform: translate3d(0, 0, 200);
              -webkit-transform: translate3d(0, 0, 200);
            }
          }
          .header-bar {
            height: 24px;
            width: 100%;
            padding: 14px 0 12px;
            position: relative;
          }
          .header-cancel {
            position: absolute;
            top: 14px;
            left: 23px;
            display: inline-block;
            height: 17.56px;
            margin-right: 8px;
          }
          .header-title {
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            display: inline-block;
            font-family: 'Noto Sans TC';
          }
          .sheet-content {
            max-height: calc(100vh - 105px);
            overflow-y: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default BottomSheet;
