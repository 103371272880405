import React, { useState, useEffect } from 'react';
import HeaderBar from '../../components/header-bar';
import BottomSheet from '../../components/bottom-sheet';
import styled, { css } from 'styled-components';

import WebFn, { callAppFunc } from '../../utils/jsbridge/index.js';
import { decode } from 'js-base64';
import { useAPI } from '../../utils/api';
import { showLoading, dismissLoading } from '../../utils/loading';
import { pageCheck } from '../../utils/pageCheck';
import { navigate } from 'gatsby';

const Container = styled.div``;

const Card = styled.div`
  padding: 24px 20px;
  background-color: #f2f2f2;
  padding-bottom: 240px;
  height: 100%;
  .redAlert {
    color: red;
  }
`;

const Btn = styled.div`
  background: #5fd2da;
  border-radius: 40px;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 17px 0 14px;
  width: 100%;

  ${(props) =>
    props.disabled &&
    css`
      background: #9d9a8a;
    `}
`;

const DeleteInfo = () => {
  const api = useAPI();
  const [mobile, setMobile] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendMemberData') {
      const dataJsonString = decode(dataJson.params.data);
      const jsonData = JSON.parse(dataJsonString);
      setMobile(jsonData.mobile);
    }
  };

  const sendValidCode = () => {
    setBtnDisable(true);
    showLoading();
    api
      .sendValidCode({ mobile: mobile })
      .then((res) => {
        if (!res) {
          setBtnDisable(false);
          dismissLoading();
          return;
        }
        dismissLoading();
        setBtnDisable(false);
        pageCheck(api, () => {
          navigate('/member/validinfo', {
            replace: false,
          });
        });
      })
      .catch((error) => {
        setBtnDisable(false);
        dismissLoading();
        console.log(error);
      });
  };

  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchMemberData', {});
  }, []);
  return (
    <>
      <HeaderBar
        title="申請刪除帳號"
        goBack={() => {
          callAppFunc('backHome', {});
        }}
      />
      <Container>
        <Card>
          <div>
            很遺憾您選擇了刪除家樂福數位會員帳號，這將會影響到您已申請的數位會員帳號登入及使用，您仍享有實體門市會員的資格，但是以下家樂福數位會員帳號功能將受到影響：
          </div>
          <br></br>
          <div>-無法登入家樂福APP/家速配APP/家樂福官網/家樂福線上購物網。</div>

          <div className="redAlert">
            -家樂福APP錢包儲值金及電子優惠券、家樂福線上購物網/家速配APP提供之任何優惠(折價券/折扣碼)，一經刪除帳號後，即無法使用，請先行確認使用完畢後再行刪除數位帳號。
          </div>
          <div>
            -家樂福線上購物網/家速配APP相關資料將一併刪除，如歷史訂單、退訂退款交易紀錄等。
          </div>
          <br></br>
          <div>
            再次提醒您，這只是刪除您的數位會員帳戶，而您仍擁有家樂福實體分店會員的資格，刪除數位帳號後所有數位會員權利一併移除，
            如有任何疑問，請電詢家樂福客服中心 : 0809-001-365
          </div>
        </Card>
      </Container>
      <BottomSheet persistant noTitle noRounded>
        <Btn
          disabled={btnDisable}
          onClick={() => {
            if (!btnDisable) sendValidCode();
          }}
        >
          確認
        </Btn>
      </BottomSheet>
    </>
  );
};

export default DeleteInfo;
